.container {
    padding: 0 $cp;
}

.container2 {
    padding: 0 $cp - 4;
}
.p-0 {
    padding: 0;
}
.p-10 {
    padding: 10px;
}
.py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}
.px-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-5 {
    padding-bottom: 5px;
}
.pb-0 {
    padding-bottom: 0;
}
.pt-10 {
    padding-top: 10px;
}
.pt-5 {
    padding-top: 5px;
}
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.m-0 {
    margin: 0;
}
.mb-10 {
    margin-bottom: 10px;
}
.mt-10 {
    margin-top: 10px;
}
.border-none {
    border: none !important;
}
svg {
    width: 24px;
    height: 24px;
}
.text-dark {
    color: $dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

a {
    text-decoration: none;
}
//Todo Visited Link Color
// a:visited {
//     color: $primary;

//     div,
//     h1,
//     h2,
//     h3,
//     h4,
//     h5,
//     h6,
//     span,
//     b,
//     p {
//         color: $primary;
//     }
// }

//bg colors
.bg_primary {
    background-color: rgba($color: $primary, $alpha: 0.1);
}
.bg_primary2 {
    background-color: rgba($color: #0bb783, $alpha: 0.1);
}

.bg_error {
    background-color: rgba($color: $error, $alpha: 0.1);
}
.bg_purple {
    background-color: rgba($color: $purple, $alpha: 0.1);
}

//un known color using
.bg_gl {
    background-color: $un_gray;
}

.bg_white {
    background-color: $white;
}

//read more
.ream_more {
    position: relative;
    height: 100%;
    .text {
        width: 100%;
    }

    .read-or-hide {
        color: currentColor;
        cursor: pointer;
        font-weight: 700 !important;
        width: auto !important;
        padding: 0;
        border-radius: 5px;
        text-align: center;
        margin-left: 3px;
    }
}

.swiper {
    z-index: auto !important;

    .swiper-wrapper {
        z-index: auto !important;
    }
    .swiper-pagination {
        z-index: auto !important;
    }
}

.swiper-pagination-bullet {
    background-color: $grey300 !important;
    opacity: 1 !important;
    width: 6px !important;
    height: 6px !important;
}
.swiper-pagination-bullet-active {
    width: 6px !important;
    height: 6px !important;
    border-radius: 6px !important;
    background-color: $primary !important;
}
.youtube_play_button {
    .swiper-pagination-bullet:last-child {
        background-color: transparent !important;

        &::before {
            display: block;
            content: url(../utils/svg/caret.svg);
            padding: 3px !important;
        }
    }
    .swiper-pagination-bullet-active:last-child {
        &::before {
            display: block;
            content: url(../utils/svg/caret-success.svg);
        }
    }
}

.prescription_slider_wrapper {
    .swiper-pagination-bullet {
        background-color: $grey200 !important;
    }
    .swiper-pagination-bullet-active {
        background-color: $primary !important;
    }
}

.brand_slider_wrapper {
    .swiper-pagination {
        bottom: 23px !important;
    }
}

#scroll_ref_slider {
    .swiper-slide-active {
        opacity: 1;
        font-size: 14px;
    }
}

.toastMain {
    z-index: 999999 !important;
    font-size: 12px;
    // animation: hideToast 5s forwards;

    // @keyframes hideToast {
    //     0% {
    //         display: block;
    //     }
    //     100% {
    //         display: none;
    //     }
    // }

    & > div {
        &:first-child {
            bottom: 65px !important;
        }
    }
    .commonParent {
        max-width: 300px;
        // color: #f2f2f2;

        .notify_box {
            display: flex;
            align-items: center;
        }
    }
    .successParent {
        background-color: $primary;
        color: $white;
        svg {
            color: $white;
        }
    }
    .errorParent {
        background-color: $error;
        color: $white;

        svg {
            color: $white;
        }
    }
    .close {
        color: $error;
        @include itemsCenter;
        animation: zoomIn 0.4s ease;

        svg {
            @include itemsCenter;
            width: 15px;
            height: 15px;
        }
    }

    .default {
        color: $primary;
    }
    .parent_notify_box {
        background-color: $dark;
        color: $white;
        @include borderRadius;
        padding: 5px;
        @include itemsCenter;
        .notify_box {
            text-transform: capitalize;
            @include itemsCenter;
        }
    }

    @keyframes zoomIn {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    // others toast
    // .toast_with_link{
    //     color: red;
    // }

    .toast_with_link_cart {
        @include itemsCenter;
        width: 100%;

        span {
            font-size: 12px;
            font-weight: 500;
            @include itemsCenter;
            svg {
                @include itemsCenter;
                // width: 16px;
                // height: 16px;
                color: $primary;
                margin-right: 5px;
            }
        }
        a {
            margin-left: 20px;
            font-size: 14px;
            color: $orange;
            font-weight: 600;
            @include itemsCenter;

            svg {
                rotate: 180deg;
            }
        }

        button {
            @include itemsCenter;
            padding: 0;
            margin: 0;
            background-color: transparent;
            border: none;
            color: $error;
            margin-right: 5px;
            svg {
                width: 16px;
                height: 16px;
            }
        }

        .iconSuccess {
            color: $primary;
        }
    }
}

.cp {
    cursor: pointer;
}
.text-capitalize {
    text-transform: capitalize;
}

.main_wrapper {
    max-width: $maxWdt;
    margin: 0 auto;
    border-right: 1px solid $grey200;
    border-left: 1px solid $grey200;
}

@media only screen and (max-width: 768px) {
    .main_wrapper {
        border: none;
    }
}

.infinite_scroll_container {
    // @include scrollBarTransparent;
    // min-height: 90vh;

    &,
    div {
        &::-webkit-scrollbar {
            width: 0;
        }
        &::-webkit-scrollbar-thumb {
            width: 0;
        }
        scrollbar-width: 0;
    }
}

#loadingParent {
    background-color: rgba(3, 5, 20, 0.664);
    z-index: 999999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .loading {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    #loader_triangle {
        stroke-dasharray: 17;
        animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
    }
    .loader_loading_text {
        font-size: 7px;
    }

    svg {
        width: 100px;
        height: 100px;
    }
    @keyframes dash {
        to {
            stroke-dashoffset: 136;
        }
    }
}

input[type="date"] {
    appearance: none;
    position: relative;
    background-color: transparent;

    &::after {
        content: url(../utils/svg/date.svg);
        position: absolute;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        opacity: 0;
        z-index: 99;
        // -webkit-appearance: none;
    }
}
#chatContainer {
    position: relative;
    // @include itemsCenter;
    z-index: 9999999;
    .chatIcon {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: none;
    }

    #webchat {
        // position: fixed;
        // top: 50px;
        // left: 50px;
        // z-index: 9999999;
        & > div > div:first-child {
            // display: none;
            // position: relative;
            // bottom: 0;
            // right: 0;
            top: 50%;
            right: 16px;
            // background-color: rgba($color: #000000, $alpha: 0.3);
            // background-color: transparent;
            padding: 0;
            // width: 25px;
            // height: 15px;

            & > svg {
                // display: none;
                // width: 15px;
                // height: 15px;
                color: $dark;
            }
        }

        // & > div > div *:not(:first-child) {
        //     div {
        //         // position: absolute;
        //     }
        // }
    }
}

.sidebar_cat_product_margin {
    .CATEGORY-LOAD_cat_product {
        margin-left: 0 !important;
    }
    .CATEGORY-LOAD_top_header_fixed {
        left: 2px !important;
        width: calc(100% - (0px * (97 / 100))) !important;
    }

    @media only screen and (min-width: 768px) {
        .CATEGORY-LOAD_top_header_fixed {
            left: calc(50% - ($maxWdt - 6px - 0px * 2) / 2) !important;
            max-width: $maxWdt - 0px - 6px !important;
        }
    }
}

.data_fetch_err_btn {
    background-color: $orange;
    border: none;
    color: $white;
    padding: 10px 25px;
    @include borderRadius;
}

.ios_label_input {
    // font-size: 16px;
    label,
    input,
    textarea {
        font-size: 16px;
    }
}
.ios_label_input_p {
    font-size: 16px;
    label,
    input,
    textarea {
        font-size: 16px;
    }
}
.ios_input_parent {
    input,
    textarea,
    .button_ios {
        font-size: 16px !important;
    }
}

.thumb_gallery_slider_main {
    .thumb_wrapper {
        .swiper-slide {
            border-bottom: 5px solid transparent;
            opacity: 0.7;
            max-height: 65px;
            border: 1px solid transparent;
            overflow: hidden;
        }
        .swiper-slide-thumb-active {
            @include transition;
            opacity: 1;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                @include transition;
                top: auto;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom: 5px solid $primary;
                pointer-events: none;
            }
        }
    }
}

.thumb_gallery_slider_main {
    .thumb_wrapper_center {
        .swiper-wrapper {
            justify-content: center;
        }
    }
}
