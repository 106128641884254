// @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css");
@import "./grids-category/main.scss";

@import "./grids/main.scss";
@import "./mixin";
@import "./variables";
@import "./blog";
@import "./presets";

* {
    // margin: 0;
    // padding: 0;
    box-sizing: border-box;
}

// html {
//     scroll-behavior: smooth;
// }
body {
    scroll-behavior: smooth;
    background-color: $bodyColor;
    // font-size: 12px;
    // line-height: 19.2px;
    margin: 0;
    // p {
    //     margin: 0;
    // }
}

// button,
// textarea,
// input,
// select,
// input,
// label,
// div,
// a {
//     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//     -webkit-tap-highlight-color: transparent;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
// }
